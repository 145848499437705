import React from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

function Awards(props) {

    return (
            <section id="one">
            <h2>Awards</h2>
            <div id="awards">
            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            Best Customer Insight
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            TravelHacks | June 2016
                        </Typography>
                        <Typography component="p">
                        My team and I developed a bot for TravelHacks called Chiribot.
                        Chiribot was supposed to help travellers and
                        tourists get connected with local tour guides.
                        This bot was equipped with artificial intelligence so it also
                        learned a few patterns to recognise different ways of asking the
                        same thing.
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            <div id="awardsCard" style={{paddingBottom: '40px'}}>    
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            Speaker at African Innovations Demonstration Talk
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px'}}>
                            TEDx Accra | April 2016
                        </Typography>
                        <Typography component="p">
                        As part of the finalist of the Africa Innovators Award 2016 I was invited to pitch at the TEDx Accra conference
                        <br />
                        <a href="https://issuu.com/tedxaccra/docs/tedx_main_catalog_small_2016">TEDx Accra 2016</a>
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            Finalist
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            Africa Innovators Award | 2016
                        </Typography>
                        <Typography component="p">
                            Joined a competition for young African entrepreneurs to pitch their ideas with the chance of winning money for investment into the company
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div>     

            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            2nd Place
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            Rise of the Bots | 2016
                        </Typography>
                        <Typography component="p">
                            A hackthon mainly targetted at students who can come up with a useful Bot. My team and I came up with a fitness
                            advisor bot which gives advice on things like exercises you could do in the gym and
                            how to do them properly by showing videos
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div> 

            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            1st Place - College Division
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            Applets Mobile Challenge | Feb 2016
                        </Typography>
                        <Typography component="p">
                        The theme at the Hackathon was 'Helping the Community'.
                        Together with my team, we developed an application called Organator. 
                        Organator is a multi-platform mobile application which promotes Organ and Tissue donation.
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            1st Place - Algonquin College Hackathon
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            Startup Algonquin Hackathon | Dec 2015
                        </Typography>
                        <Typography component="p">
                        A team of three people developed a web application for finding government instituions, parks and other places of interest for people new to the city
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            Dean's Honour List
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            Algonquin College | 2014 - 2017
                        </Typography>
                        <Typography component="p">
                        Awarded to students with a GPA of 3.60 and 4.00 at the end of each term
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div id="awardsCard" style={{paddingBottom: '40px'}}>
                <Card>
                    <CardContent>
                        <Typography variant="headline" component="h2" color="primary">
                            Semi-Finalist
                        </Typography>
                        <Typography color="textSecondary" style={{paddingBottom: '5px', paddingTop: '5px',}}>
                            Ghana's Next Young Entrepreneur | 2014
                        </Typography>
                        <Typography component="p">
                            Entrepreneurship competition for Ghanaians between 18-30
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </div>    
            </div>    
            </section>
    )
}

export default Awards