import React from 'react'
import AwardsComp from '../components/Awards'
import Layout from '../components/layout'
const Awards = (props) => {

    return (
        <Layout selected='awards'>
            <AwardsComp/>
        </Layout>
    )
}

export default Awards